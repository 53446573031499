<template>
  <div class="app-footer">
    <div class="app-footer-inner">
      <div class="link-group">
        <h3>パチンコ店</h3>
        <div class="links area" v-if="listData?.cityData">
          <!--  -->
          <div class="link" v-for="c in listData.cityData">
            <nuxt-link :title="`pachinko/slots ${c.cityname}の最新パチンコ店(${c.shopcnt})`" target="_blank" :to="{ name: 'store-area-page.html', params: { area: Areds[c.cityname], page: 1 } }">{{ c.cityname }}({{ c.shopcnt }})</nuxt-link>
          </div>
        </div>
        <h3>パチンコゲーム機</h3>
        <div class="links area">
          <div class="link">
            <nuxt-link :title="`パチンコ`" target="_blank" :to="{ name: 'machine-category-type-area-page.html', params: { category: 'pachinko', type: '全て', area: '全て', page: 1 } }">パチンコ</nuxt-link>
          </div>
          <div class="link">
            <nuxt-link :title="`パチスロ`" target="_blank" :to="{ name: 'machine-category-type-area-page.html', params: { category: 'slot', type: '全て', area: '全て', page: 1 } }">パチスロ</nuxt-link>
          </div>
        </div>
        <h3></h3>
        <div class="links center">
          <div class="link">
            <nuxt-link :title="`免責事項`" target="_blank" to="">免責事項</nuxt-link>
          </div>
          <div class="link">
            <nuxt-link :title="`私たちについて`" target="_blank" to="">私たちについて</nuxt-link>
          </div>
          <div class="link">
            <nuxt-link :title="`联系我们`" target="_blank" to="">联系我们</nuxt-link>
          </div>
        </div>
      </div>
      <div class="copyright">
        <a href="/index.html">パチンコ</a>ワールドPachinkoworldに掲載の記事・情報・写真の無断転載を禁じます。すべての内容は日本の著作権法並びに国際条約により保護されています。
        <br />© 2024 PACHINKOWORLD <a href="/index.html">パチンコ</a>ワールド
      </div>
    </div>
  </div>
</template>

<script setup>
const listData = reactive({
  cityData: []
})
const Areds = useAreds()
async function getData () {
  let cityData, pagination = {};
  cityData =  await $fetch(`/api/cityshops?pagination[page]=1&pagination[pageSize]=100`).then(res => {
    return res.cityshops.data
  }).catch(e => {
    return null
  })
  return { cityData }
}
const asData = await useAsyncData(getData)
listData.cityData = asData.data.value.cityData
</script>

<style lang="less" scoped>
.app-footer {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: solid 1px #eee;
}
.app-footer-inner {
  width: 80%;
  padding: 20px 0 30px;
}
h3 {
  margin: 10px 0 0 0;
}
.links {
  display: flex;
  flex-flow: row wrap;
  &.center {
    justify-content: center;
  }
  .link {
    padding: 3px 0 0 10px;
    a {
      color: #666;
    }
  }
  &.area {
    .link {
      min-width: 84px;
    }
  }
}
.copyright {
  font-size: 12px;
  color: #666;
  margin-top: 20px;
  text-align: center;
  a,
  a:hover,
  a:active,
  a:visited {
    color: #666;
    text-decoration: none;
  }
}
</style>
