declare global {
  interface Window {
    _paq?: any[]
  }
}

import { defineNuxtPlugin, useRouter } from 'nuxt/app'
import { onMounted } from 'vue'
import VueMatomo from 'vue-matomo'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueMatomo, {
    host: 'https://api.pachinkoworld.com/matomo',
    siteId: 1
  })

  const router = useRouter()

  onMounted(() => {
    if (window._paq) {
      window._paq.push(['trackPageView'])
    }
  })

  router.afterEach(() => {
    if (window._paq) {
      window._paq.push(['trackPageView'])
    }
  })
})