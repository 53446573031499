const Areds = {
  '栃木県': 'tochigi',
  '愛媛県': 'ehime',
  '熊本県': 'kumamoto',
  '香川県': 'kagawa',
  '島根県': 'shimane',
  '福井県': 'fukui',
  '兵庫県': 'hyogo',
  '佐賀県': 'saga',
  '宮崎県': 'miyazaki',
  '愛知県': 'aichi',
  '新潟県': 'niigata',
  '山梨県': 'yamanashi',
  '鳥取県': 'tottori',
  '沖縄県': 'okinawa',
  '石川県': 'ishikawa',
  '高知県': 'kochi',
  '茨城県': 'ibaraki',
  '群馬県': 'gunma',
  '和歌山県': 'wakayama',
  '三重県': 'mie',
  '岐阜県': 'gifu',
  '広島県': 'hiroshima',
  '埼玉県': 'saitama',
  '静岡県': 'shizuoka',
  '岡山県': 'okayama',
  '山口県': 'yamaguchi',
  '山形県': 'yamagata',
  '北海道': 'hokkaido',
  '長崎県': 'nagasaki',
  '神奈川県': 'kanagawa',
  '千葉県': 'chiba',
  '福岡県': 'fukuoka',
  '大阪府': 'osaka',
  '富山県': 'toyama',
  '大分県': 'oita',
  '徳島県': 'tokushima',
  '滋賀県': 'shiga',
  '東京都': 'tokyo',
  '鹿児島県': 'kagoshima',
  '秋田県': 'akita',
  '京都府': 'kyoto',
  '奈良県': 'nara',
  '青森県': 'aomori',
  '福島県': 'fukushima',
  '宮城県': 'miyagi',
  '岩手県': 'iwate',
  '長野県': 'nagano',
}

export const useAreds = () => {
  return Areds
}

export const useAredsEn = () => {
  const AredsEn = {}

  for (const [key, value] of Object.entries(Areds)) {
    AredsEn[value] = key
  }

  return AredsEn
}