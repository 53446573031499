import { default as index_46htmlpnpK0BYpaoMeta } from "/root/nuxt/city-live-pc/pages/index.html.vue?macro=true";
import { default as _91page_93_46htmlARt4n2Kd6wMeta } from "/root/nuxt/city-live-pc/pages/machine/[category]/[type]/[area]/[page].html.vue?macro=true";
import { default as _91id_93_46htmlgh6pxt513FMeta } from "/root/nuxt/city-live-pc/pages/machine/[id].html.vue?macro=true";
import { default as machines_46htmlsXj7K0p2a4Meta } from "/root/nuxt/city-live-pc/pages/machines.html.vue?macro=true";
import { default as _91page_93_46htmlEQFmFc0gwCMeta } from "/root/nuxt/city-live-pc/pages/store/[area]/[page].html.vue?macro=true";
import { default as _91id_93_46htmlClH1fruNPQMeta } from "/root/nuxt/city-live-pc/pages/store/[id].html.vue?macro=true";
import { default as strategy_46htmlAzYfWBJgQNMeta } from "/root/nuxt/city-live-pc/pages/strategy.html.vue?macro=true";
import { default as _91id_93_46htmlPwB3f8kZFzMeta } from "/root/nuxt/city-live-pc/pages/strategy/topic/[id].html.vue?macro=true";
import { default as _91page_93_46htmlNCxlBtw2etMeta } from "/root/nuxt/city-live-pc/pages/strategy/topic/list/[page].html.vue?macro=true";
import { default as _91id_93_46htmlnTzLfdNdSWMeta } from "/root/nuxt/city-live-pc/pages/strategy/video/[id].html.vue?macro=true";
import { default as _91page_93_46html43Sq4qluAAMeta } from "/root/nuxt/city-live-pc/pages/strategy/video/list/[page].html.vue?macro=true";
export default [
  {
    name: "index.html",
    path: "/index.html",
    component: () => import("/root/nuxt/city-live-pc/pages/index.html.vue")
  },
  {
    name: "machine-category-type-area-page.html",
    path: "/machine/:category()/:type()/:area()/:page().html",
    component: () => import("/root/nuxt/city-live-pc/pages/machine/[category]/[type]/[area]/[page].html.vue")
  },
  {
    name: "machine-id.html",
    path: "/machine/:id().html",
    component: () => import("/root/nuxt/city-live-pc/pages/machine/[id].html.vue")
  },
  {
    name: "machines.html",
    path: "/machines.html",
    component: () => import("/root/nuxt/city-live-pc/pages/machines.html.vue")
  },
  {
    name: "store-area-page.html",
    path: "/store/:area()/:page().html",
    component: () => import("/root/nuxt/city-live-pc/pages/store/[area]/[page].html.vue")
  },
  {
    name: "store-id.html",
    path: "/store/:id().html",
    component: () => import("/root/nuxt/city-live-pc/pages/store/[id].html.vue")
  },
  {
    name: "strategy.html",
    path: "/strategy.html",
    component: () => import("/root/nuxt/city-live-pc/pages/strategy.html.vue")
  },
  {
    name: "strategy-topic-id.html",
    path: "/strategy/topic/:id().html",
    component: () => import("/root/nuxt/city-live-pc/pages/strategy/topic/[id].html.vue")
  },
  {
    name: "strategy-topic-list-page.html",
    path: "/strategy/topic/list/:page().html",
    component: () => import("/root/nuxt/city-live-pc/pages/strategy/topic/list/[page].html.vue")
  },
  {
    name: "strategy-video-id.html",
    path: "/strategy/video/:id().html",
    component: () => import("/root/nuxt/city-live-pc/pages/strategy/video/[id].html.vue")
  },
  {
    name: "strategy-video-list-page.html",
    path: "/strategy/video/list/:page().html",
    component: () => import("/root/nuxt/city-live-pc/pages/strategy/video/list/[page].html.vue")
  }
]